<template>
  <div class="location">
    <router-view v-if="$route.name !== 'Location'" />
    <div v-else>
      <v-row class="mb-4">
        <v-col
          cols="12"
          md="4">
          <h1> {{ $t('app.page.location.title') }} </h1>
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-text-field
            :value="search"
            :placeholder="$t('app.placeholder.search')"
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            hide-details
            @input="debounceSearching($event)" />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-select
            v-model="selectedFilter"
            :placeholder="$t('app.placeholder.filter')"
            :items="filters"
            item-text="text"
            item-value="value"
            clearable
            dense
            outlined
            hide-details />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-select
            v-model="statusFilter"
            :items="statusItems"
            item-text="text"
            item-value="value"
            placeholder="select status .."
            dense
            outlined
            hide-details />
        </v-col>
        <v-col
          col="12"
          md="2">
          <div class="text-right">
            <v-btn
              color="primary"
              depressed
              @click="create()">
              {{ $t('app.btn.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="items"
        :loading-text="$t('vuetify.loading')"
        :no-data-text="$t('vuetify.noData')"
        hide-default-footer>
        <template v-slot:item.type="{ item }">
          <v-chip
            :color="colorType(item)"
            label
            small>
            {{ item.type }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            v-if="item.status === 'INACTIVE'"
            color="primary"
            small
            depressed>
            {{ $t('app.btn.active') }}
          </v-btn>
        </template>
        <template v-slot:item.options="{ item }">
          <div class="d-flex">
            <v-icon
              class="mr-3"
              @click="map(item)">
              mdi-map-marker-radius-outline
            </v-icon>
            <v-icon
              class="mr-2"
              @click="update(item.id)">
              mdi-square-edit-outline
            </v-icon>
            <v-icon
              @click="remove(item.id)">
              mdi-delete-outline
            </v-icon>
          </div>
        </template>
      </v-data-table>
      <div class="d-flex justify-center justify-md-end mt-6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { location } from '@/mixins/location'
import LocationProvider from '@/resources/axios/providers/location'

export default {
  name: 'Location',
  mixins: [location],
  data () {
    return {
      search: '',
      selectedFilter: null,
      statusFilter: 'ACTIVE',
      filters: [
        {
          text: 'Code',
          value: 'code'
        },
        {
          text: 'Name',
          value: 'name'
        }
      ],
      statusItems: [
        {
          text: this.$t('app.select.active'),
          value: 'ACTIVE'
        },
        {
          text: this.$t('app.select.inactive'),
          value: 'INACTIVE'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      myLocation: 'local/myLocation'
    })
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'myLocation.lat' () {
      this.getLocation()
    },
    selectedFilter () {
      this.page = 1
      this.getLocation()
    },
    statusFilter () {
      this.page = 1
      this.getLocation()
    }
  },
  created () {
    this.debounceSearching = this.$_.debounce(this.setSearch, 800)
  },
  mounted () {
    if (this.myLocation.lat && this.myLocation.lng) {
      this.getLocation()
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    setSearch (search) {
      this.search = search
      this.getLocation()
    },
    async remove (id) {
      try {
        const res = await LocationProvider.deleteLocation(id)
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.delete'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.getLocation()
        }
      } catch (error) {
        console.error(error)
      }
    },
    create () {
      this.$router.push({ name: 'LocationCreate' })
    },
    update (id) {
      this.$router.push({ name: 'LocationUpdate', params: { locationId: id } })
    },
    map (item) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${this.myLocation.lat},${this.myLocation.lng}&destination=${item.latitude},${item.longitude}`,
        '_blank'
      )
    }
  }
}
</script>

<style scoped>
  .location {
    padding: 16px 24px;
  }
</style>
