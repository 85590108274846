import LocationProvider from '@/resources/axios/providers/location'

export const location = {
  data () {
    return {
      page: 1,
      limit: 7,
      totalPage: 1,
      isLoading: true,
      headers: [
        { text: this.$t('app.table.header.locationName'), sortable: false, value: 'name' },
        { text: this.$t('app.table.header.telephone'), sortable: false, value: 'phoneNo' },
        { text: this.$t('app.table.header.type'), sortable: false, value: 'type' },
        { text: this.$t('app.table.header.latitude'), sortable: false, value: 'latitude' },
        { text: this.$t('app.table.header.longitude'), sortable: false, value: 'longitude' },
        { text: this.$t('app.table.header.province'), sortable: false, value: 'province' },
        { text: this.$t('app.table.header.district'), sortable: false, value: 'district' },
        { text: this.$t('app.table.header.subDistrict'), sortable: false, value: 'subDistrict' },
        { text: '', sortable: false, value: 'action' },
        { text: '', sortable: false, value: 'options' }
      ],
      items: []
    }
  },
  watch: {
    page () {
      this.getLocation()
    }
  },
  methods: {
    colorType (item) {
      if (item.type === 'rescue') {
        return 'primary'
      } if (item.type === 'fire') {
        return 'warning'
      }
      return 'error'
    },
    async getLocation () {
      try {
        this.isLoading = true
        const res = await LocationProvider.getLocationList({
          page: this.page,
          limit: this.limit,
          filter: this.selectedFilter ? `&${this.selectedFilter}=${this.search}` : '',
          status: this.statusFilter,
          groups: '',
          province: '',
          type: ''
        })
        if (res) {
          this.items = res.records
          this.totalPage = res.totalPage
          this.isLoading = false
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export const locationForm = {
  data () {
    return {
      valid: true,
      isSubmitting: false,
      roles: [
        {
          text: this.$t('app.select.rescue'),
          value: 'rescue'
        },
        {
          text: this.$t('app.select.fire'),
          value: 'fire'
        },
        {
          text: this.$t('app.select.medical'),
          value: 'medical'
        }
      ],
      statusItems: [
        {
          text: this.$t('app.select.active'),
          value: 'ACTIVE'
        },
        {
          text: this.$t('app.select.inactive'),
          value: 'INACTIVE'
        }
      ],
      form: {
        code: '',
        name: '',
        description: '',
        phoneNo: '',
        type: 'rescue',
        latitude: null,
        longitude: null,
        address: '',
        province: '',
        district: '',
        subDistrict: '',
        postcode: '',
        status: 'ACTIVE'
      },
      onkeypress: 'return event.charCode >= 48 && event.charCode <= 57',
      codeRules: [(v) => !!v || this.$t('app.validate.locationCode')],
      nameRules: [(v) => !!v || this.$t('app.validate.locationName')],
      descriptionRules: [(v) => !!v || this.$t('app.validate.description')],
      phoneNoRules: [(v) => !!v || this.$t('app.validate.telephone')],
      typeRules: [(v) => !!v || this.$t('app.validate.type')],
      latitudeRules: [
        (v) => !!v || this.$t('app.validate.latitude'),
        (v) => (String(v).charAt(0) !== '0' && String(v).charAt(0) !== '.') || 'กรุณากรอกละติจูดให้ถูกต้อง'
      ],
      longitudeRules: [
        (v) => (!!v && v !== '0') || this.$t('app.validate.longitude'),
        (v) => (String(v).charAt(0) !== '0' && String(v).charAt(0) !== '.') || 'กรุณากรอกลองติจูดให้ถูกต้อง'
      ],
      provinceRules: [(v) => !!v || this.$t('app.validate.province')],
      districtRules: [(v) => !!v || this.$t('app.validate.district')],
      subDistrictRules: [(v) => !!v || this.$t('app.validate.subDistrict')],
      postcodeRules: [(v) => !!v || this.$t('app.validate.postcode')]
    }
  }
}

export default {
  location,
  locationForm
}
