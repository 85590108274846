import HttpRequest from '@/resources/axios/core/http-request'
import store from '@/store'
import axios from 'axios'

class LocationProvider extends HttpRequest {
  getLocationList = (params) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(
      `/locations?page=${params.page}`
      + `&limit=${params.limit}`
      + `&status=${params.status}`
      + '&orderBy=id&sortBy=desc'
      + `&groups=${params.groups}`
      + `&province=${params.province}`
      + `&type=${params.type}`
    )
  }

  getLocationOne = (param) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(`/locations/${param}`)
  }

  createLocation = (payload) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.post('/locations', payload)
  }

  updateLocation = (param, payload) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.put(`/locations/${param}`, payload)
  }

  deleteLocation = (param) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.delete(`/locations/${param}`)
  }

  importLocation = (payload) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.post('/locations/imports', payload)
  }

  importLocation = (payload) => axios.post('https://api-rescue-station.duskyhub.com/locations/imports',
    payload, {
    headers: {
      'Authorization': `Bearer ${store.getters['auth/user'].accessToken}`,
      'Content-Type': 'multipart/form-data'
    }
  })

  getNumberGroups = () => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get('/locations/groups')
  }
}

export default new LocationProvider()
